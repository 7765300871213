import ApiService, { HTTPMethod } from './api.core'
import { Transformer } from '../models/Transformer'
import {
    isToday,
    getAiWithoutId,
    equivalentAdditionalInsured,
    removeDuplicateAdditionalInsured,
    isTodayBreakdown,
} from '../services/utils'
import { FirebaseService, FirebaseConfigKey } from '../services/FirebaseService'
import { COUNTRIES } from '../Constants'
import { SessionService } from '../services/SessionService'
import { COVERAGE_TYPES } from '../models/insurance-models/CoverageType'
import { WorldWideOption } from '../models/insurance-models/WorldWideOption'

class InsuranceApi {
    async loadOffers(
        subscriptionStartDate: Date,
        maximumUasInAir = 1,
        coverageType = COVERAGE_TYPES.COMBINED,
        drones = [],
        equipment = [],
        operators = [],
        personalInjuryCoverage = 0,
        medicalExpense = 0,
        worldWideCoverage = {
            territorialLimits: WorldWideOption.Default,
            territorialLimitsDescription: '',
        },
        indoorCoverage = { included: false, description: '' },
        specialPrice = null
    ) {
        let body = (!subscriptionStartDate || isTodayBreakdown(subscriptionStartDate.getUTCDate(), subscriptionStartDate.getUTCMonth(), subscriptionStartDate.getUTCFullYear())) ? {} : {
            "start_time": subscriptionStartDate.getTime()
        };

        if (indoorCoverage.included) {
            body.indoor_coverage = indoorCoverage.included
            body.indoor_coverage_description = indoorCoverage.description
        }

        if (specialPrice != null) {
            body.custom_premium = specialPrice.custom_premium
            body.custom_premium_token = specialPrice.custom_premium_token
        }

        if (
            FirebaseService.getValue(FirebaseConfigKey.COUNTRY) == COUNTRIES.UK
        ) {
            body.maximum_uas_in_air = maximumUasInAir
            body.coverage_type = coverageType
            body.operators = operators
        }

        if (!SessionService.isLoggedIn()) {
            body.drones = drones
            body.equipment = equipment
            body.operators = operators
        }

        body.medical_expense_limit = medicalExpense
        body.personal_injury_limit = personalInjuryCoverage
        body.territorial_limits_description =
            worldWideCoverage.territorialLimitsDescription
        body.territorial_limits = worldWideCoverage.territorialLimits

        let res = await ApiService.fetch(
            'subscriptions/offers',
            HTTPMethod.POST,
            body
        )

        if (res.ok) {
            res.monthlyOffers = Transformer.parseOffers(res.data['offers'])
            res.annualOffers = Transformer.parseOffers(
                res.data['annual_offers']
            )
        }
        return res
    }

    async submitAviationForm(forminformation) {
        let res = await ApiService.fetch(
            'aviationformsubmit',
            HTTPMethod.POST,
            forminformation
        )
        return res
    }

    async getActiveInsurances() {
        let res = await ApiService.fetch('insurances/me/active')
        if (res.ok && res.data && res.data.length > 0) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(
                res.data[0]
            )
            res.parsedData = activeInsurance
        }
        return res
    }

    async purchaseInsurance(
        optionId,
        additionalInsured,
        autoRenew,
        externalActorId,
        paymentMethod
    ) {
        let body = {}
        body.offer_id = optionId
        body.auto_renew = !!autoRenew
        body.payment_method = paymentMethod

        if (externalActorId) {
            body.external_actor_id = externalActorId
        }

        if (additionalInsured) {
            body.additional_insureds = removeDuplicateAdditionalInsured(
                additionalInsured.map((q) => getAiWithoutId(q))
            )
        }

        let res = await ApiService.fetch('insurances/', HTTPMethod.POST, body)

        if (res.ok) {
            let activeInsurance = Transformer.parseActiveInsuranceModel(
                res.data
            )
            res.parsedData = activeInsurance
        }
        return res
    }

    async sendAQuote(
        offersContext,
        emails,
        companyName
    ) {
        let body = {
            emails: emails,
            name: companyName,
            auto_renew: !!offersContext.autoRenew,
            billing_period:offersContext.pricebreakdown.insuranceDuration,
            bodily_injury_liability_limit: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.bodilyInjuryLiability.liability,
            aircraft_damage_liability_limit: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.aircraftDamageLiability.liability,
            medical_expense_limit: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.medicalPayments.liability,
            custom_premium: offersContext.pricebreakdown.price ,
            medical_expense_price: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.medicalPayments.price,
            bodily_injury_liability_price: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.bodilyInjuryLiability.price,
            aircraft_damage_liability_price: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.aircraftDamageLiability.price,
            deductible_reimbursement_limit: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.deductibleReimbursement.liability,
            deductible_reimbursement_price: offersContext.pricebreakdown.vehicleCoveragePrices[Object.keys(offersContext.pricebreakdown.vehicleCoveragePrices)[0]].coveragePrices.deductibleReimbursement.price,
            tax: offersContext.pricebreakdown.tax

        }
        if (
            offersContext.subscriptionStartTime &&
            !isToday(offersContext.subscriptionStartTime)
        ) {
            body['start_time'] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch('insurances/quote', HTTPMethod.POST, body)
    }

    async saveQuoteState(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(
                insurancePurchaseContext.additionalInsuredList.map((q) =>
                    getAiWithoutId(q)
                )
            ),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period:
                insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits:
                offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description:
                offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token:
                offersContext.specialPrice.custom_premium_token,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description:
                offersContext.indoorCoverage.description,
        }
        if (
            offersContext.subscriptionStartTime &&
            !isToday(offersContext.subscriptionStartTime)
        ) {
            body['start_time'] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch(
            'insurances/purchaseState',
            HTTPMethod.POST,
            body
        )
    }

    async runBordereaux(from, to,includePriceReport) {
        return await ApiService.fetch(
            'insurances/run-bordereaux',
            HTTPMethod.POST,
            {from, to, include_price_report: includePriceReport},
            true,
            false,
            true
        )
    }

    async referQuote(insurancePurchaseContext, offersContext) {
        let body = {
            liability: insurancePurchaseContext.selectedOption.liabilityLimit,
            auto_renew: !!insurancePurchaseContext.autoRenew,
            additional_insureds: removeDuplicateAdditionalInsured(
                insurancePurchaseContext.additionalInsuredList.map((q) =>
                    getAiWithoutId(q)
                )
            ),
            drones: insurancePurchaseContext.drones || [],
            equipment: insurancePurchaseContext.equipment || [],
            billing_period:
                insurancePurchaseContext.selectedOption.billingPeriod,
            coverage_type: offersContext.coverageType,
            operators: offersContext.operators,
            maximum_uas_in_air: offersContext.maximumUasInAir,
            territorial_limits:
                offersContext.worldWideCoverage.territorialLimits,
            territorial_limits_description:
                offersContext.worldWideCoverage.territorialLimitsDescription,
            personal_injury_limit: offersContext.personalInjuryCoverage,
            medical_expense_limit: offersContext.medicalExpense,
            custom_premium: offersContext.specialPrice.custom_premium,
            custom_premium_token:
                offersContext.specialPrice.custom_premium_token,
            indoor_coverage: offersContext.indoorCoverage.included,
            indoor_coverage_description:
                offersContext.indoorCoverage.description,
        }
        if (
            offersContext.subscriptionStartTime &&
            !isToday(offersContext.subscriptionStartTime)
        ) {
            body['start_time'] = offersContext.subscriptionStartTime.getTime()
        }
        return await ApiService.fetch(
            'insurances/refer-quote',
            HTTPMethod.POST,
            body
        )
    }
}

export default new InsuranceApi()
