import React from 'react'
import { Typography, Grid, Container } from '@material-ui/core';
import './Congratulations.scss'

export const Declination = () => {
    return (
            <div className="underwriter-bg-img">
                <Typography class="underwriter-main-title"><span className='super-light-blue margin-bottom-large' style={{fontWeight: 'bolder'}}>Underwriter approval <br/> required</span></Typography>
                <div className="declination-text">
                <Typography className='white bold'>Your policy is under review</Typography>
                <br/>
                <Typography variant='body1' className='white'>Our underwriters are checking your information, <br/>we will contact you as soon as we finish.</Typography>
                <Typography variant='body1' className='white margin-bottom-medium'>If you have any questions feel free to call our support team <br/>1 (888) 849-4902</Typography>
                </div>
            </div>
    )

}

